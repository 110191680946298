<article>
  <div class="registrationWrapper">
    <header #header>
      <div class="mobile-menu">
        <button type="button" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Mobile menu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="{{ getURLLangPart() }}/auth/login">
            <mat-icon>login</mat-icon>
            <span class="s16">{{ 'BUTTONS.LOGIN' | translate }}</span>
          </button>
          <button mat-menu-item (click)="scroll(benefits)">
            <mat-icon>dialpad</mat-icon>
            <span class="s16">{{ 'LINKS.FEATURES' | translate }}</span>
          </button>
          <button mat-menu-item (click)="scroll(subscriptions)">
            <mat-icon>discount</mat-icon>
            <span class="s16">{{ 'LINKS.SUBSCRIPTIONS' | translate }}</span>
          </button>
          <button mat-menu-item (click)="scroll(reviews)">
            <mat-icon>reviews</mat-icon>
            <span class="s16">{{ 'LINKS.REVIEWS' | translate }}</span>
          </button>
        </mat-menu>
      </div>
      <div>
        <div class="logo">
          <a routerLink="{{ getURLLangPart() }}/">
            <img src="assets/images/logos/logo-wy.svg" class="logo" alt="DinoLingo Logo" />
          </a>
        </div>
        <nav>
          <a class="s16" href="#benefits" (click)="scroll(benefits)">{{ 'LINKS.FEATURES' | translate }}</a>
          <a class="s16" href="#subscriptions" (click)="scroll(subscriptions)">{{ 'LINKS.SUBSCRIPTIONS' | translate
            }}</a>
          <a class="s16" href="#reviews" (click)="scroll(reviews)">{{ 'LINKS.REVIEWS' | translate }}</a>
        </nav>
      </div>
      <div>
        <div>
          <a class="login-btn s16" routerLink="{{ getURLLangPart() }}/auth/login">
            {{ 'BUTTONS.LOGIN' | translate }}
          </a>
          <a mat-raised-button class="action-btn s16" href="#" (click)="scroll(signup)">
            {{ 'BUTTONS.CONTINUE_FREE' | translate }}
          </a>
          <localization class="big"></localization>
        </div>
      </div>
    </header>
    <section class="registration">
      <div class="left-column">
        <h1 class="s48 lh74 m-s32 m-lh36 baloo">{{ 'REGISTRATION.TITLE' | translate }}</h1>
        <p class="s18 lh32 m-s14 m-lh20" [innerHTML]="'REGISTRATION.DESCRIPTION' | translate"></p>
        <img src="assets/images/dinosaurs/oneDino.svg" alt="Dino" />
      </div>
      <div class="right-column" #signup>
        <form *ngIf="!processWithGoogle && !processWithApple" [formGroup]="signUpFormGroup"
          (ngSubmit)="registerUser(signUpFormGroup)" class="signUp">
          <h2 class="baloo s24" [innerHTML]="'PROFILE.SIGN_UP' | translate"></h2>
          <p class="s16">{{ 'PROFILE.SIGN_UP_THEN' | translate }}</p>
          <div class="inputGroup">
            <mat-form-field class="newDinoStyle">
              <mat-label>{{ 'REGISTRATION.NAME' | translate }}</mat-label>
              <input matInput type="text" required maxlength="10" formControlName="nickname" #nickname />
            </mat-form-field>
            <ng-container *ngIf="signUpFormGroup.get('nickname').invalid">
              <mat-error
                *ngIf="signUpFormGroup.get('nickname').errors.minlength && !signUpFormGroup.get('nickname').errors.required">{{
                'VALIDATION.NAME_MIN_LENGTH' | translate }}</mat-error>
            </ng-container>
          </div>
          <div class="inputGroup">
            <mat-form-field class="select-dinostyle">
              <mat-select [(value)]="selectedCourse" formControlName="courseId"
                placeholder="{{ 'PLACEHOLDERS.SIGNUP_CHOOSE_LANGUAGE' | translate }}">
                <ng-container *ngFor="let course of courses$ | async">
                  <mat-option [value]="course.id" *ngIf="course.active">{{ formatCourseName(course) }} </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="inputGroup">
            <mat-form-field class="newDinoStyle">
              <mat-label>{{ 'PROFILE.EMAIL_ENTER' | translate }}</mat-label>
              <input matInput type="email" required formControlName="email" />
            </mat-form-field>
          </div>
          <div class="inputGroup">
            <mat-form-field class="newDinoStyle">
              <mat-label>{{ 'PROFILE.EMAIL_CONFIRM' | translate }}</mat-label>
              <input matInput type="email" required formControlName="emailConfirm" />
            </mat-form-field>
            <mat-error *ngIf="signUpFormGroup.hasError('notMatched')">
              <span class="danger">{{ 'VALIDATION.EMAILS_NOT_MATCHED' | translate }}</span>
            </mat-error>
          </div>
          <div class="inputGroup autoheight">
            <mat-form-field class="newDinoStyle">
              <mat-label>{{ 'PROFILE.PASSWORD' | translate }}</mat-label>
              <input matInput type="password" [type]="hidePassword ? 'password' : 'text'" required
                formControlName="password" maxlength="20" />
            </mat-form-field>
            <div class="hide">
              <mat-icon (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility' : 'visibility_off'
                }}</mat-icon>
            </div>
            <ng-container
              *ngIf="(signUpFormGroup.get('password').dirty || signUpFormGroup.get('password').touched) && signUpFormGroup.get('password').invalid">
              <mat-error
                *ngIf="(signUpFormGroup.get('password').errors.invalidPassword || signUpFormGroup.get('password').errors.minlength) && !signUpFormGroup.get('password').errors.maxlength">
                {{ 'VALIDATION.PASSWORD_RULES' | translate }}
              </mat-error>
              <mat-error *ngIf="signUpFormGroup.get('password').errors.maxlength">{{ 'VALIDATION.PASSWORD_MAX_LENGTH' |
                translate }}</mat-error>
            </ng-container>
          </div>
          <div class="inputGroup">
            <mat-form-field class="select-dinostyle">
              <mat-select formControlName="period" required
                placeholder="{{ 'SUBSCRIPTIONS.ENTER_PAYMENT' | translate }}" disableOptionCentering>
                <mat-option [value]="planPeriod.yearly"
                  [innerHTML]="'SUBSCRIPTIONS.PRICE_TITLE_YEARLY_HTML' | translate">{{
                  'SUBSCRIPTIONS.PRICE_TITLE_YEARLY' | translate }}</mat-option>
                <mat-option [value]="planPeriod.monthly">{{ 'SUBSCRIPTIONS.PRICE_TITLE_MONTHLY' | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <small><a href="https://dinolingo.com/" target="_blank">{{
              'REGISTRATION.READ_MORE' | translate }}</a></small>
          <div class="inputGroup shifted">
            <mat-form-field class="select-dinostyle">
              <mat-select formControlName="paymentMethod" required
                placeholder="{{ 'REGISTRATION.CHOOSE_PAYMENT_METHOD' | translate }}" disableOptionCentering>
                <mat-option value="stripe">{{ 'PAYMENTS.CREDIT_CARD' | translate }} <img
                    src="assets/images/icons/credit-card.png" alt="Stripe" /></mat-option>
                <mat-option value="paypal">PayPal <img src="assets/images/logos/paypal-logo.png"
                    alt="PayPal" /></mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <small>{{ 'PROFILE.SEVEN_DAYS_FREE' | translate }}</small>
          <div class="terms newDinoStyle">
            <mat-checkbox color="primary" (change)="manageTerms()" [class]="{redAlert}">{{ 'PROFILE.TEXT_ACCEPT' |
              translate }}<br>
              <a href="https://www.dinolingo.com/terms-and-conditions/" target="_blank">{{ 'PROFILE.TEXT_TERMS' |
                translate
                }}</a>
              {{ 'PROFILE.TEXT_AND' | translate }}
              <a href="https://www.dinolingo.com/privacy-policy/" target="_blank">{{ 'PROFILE.TEXT_POLICY' |
                translate }}</a>.</mat-checkbox>
          </div>
          <div class="inputGroup nolabel">
            <button mat-raised-button class="login-btn s18" type="submit" [disabled]="formSent" color="accent"
              (click)="checkTerms()">
              {{ 'BUTTONS.CONTINUE_FREE' | translate }}
            </button>
          </div>
          <div *featureEnabled="'web-social-register'">
            <div class="inputGroup">
              <asl-google-signin-button type='standard' size='medium' text='signup_with'></asl-google-signin-button>
            </div>
          </div>
        </form>

        <form *ngIf="processWithGoogle || processWithApple" [formGroup]="signUpSocFormGroup"
          (ngSubmit)="registerUserWithSoc(signUpSocFormGroup)" class="signUp">
          <h2 class="baloo s24" [innerHTML]="'PROFILE.SIGN_UP' | translate"></h2>
          <p class="s16">{{ 'PROFILE.SIGN_UP_THEN' | translate }}</p>
          <div class="inputGroup">
            <mat-form-field class="newDinoStyle">
              <mat-label>{{ 'REGISTRATION.NAME' | translate }}</mat-label>
              <input matInput type="text" required maxlength="10" formControlName="nickname" />
            </mat-form-field>
            <ng-container *ngIf="signUpFormGroup.get('nickname').invalid">
              <mat-error
                *ngIf="signUpFormGroup.get('nickname').errors.minlength && !signUpFormGroup.get('nickname').errors.required">{{
                'VALIDATION.NAME_MIN_LENGTH' | translate }}</mat-error>
            </ng-container>
          </div>
          <div class="inputGroup">
            <mat-form-field class="select-dinostyle">
              <mat-select [(value)]="selectedCourse" formControlName="courseId"
                placeholder="{{ 'PLACEHOLDERS.SIGNUP_CHOOSE_LANGUAGE' | translate }}">
                <ng-container *ngFor="let course of courses$ | async">
                  <mat-option [value]="course.id" *ngIf="course.active">{{ formatCourseName(course) }} </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="inputGroup" *ngIf="processWithGoogle">
            <mat-form-field class="newDinoStyle">
              <mat-label>{{ 'PROFILE.EMAIL_ENTER' | translate }}</mat-label>
              <input matInput type="email" required formControlName="email" readonly />
            </mat-form-field>
          </div>
          <div class="inputGroup">
            <mat-form-field class="select-dinostyle">
              <mat-select formControlName="period" required
                placeholder="{{ 'SUBSCRIPTIONS.ENTER_PAYMENT' | translate }}" disableOptionCentering>
                <mat-option [value]="planPeriod.yearly"
                  [innerHTML]="'SUBSCRIPTIONS.PRICE_TITLE_YEARLY_HTML' | translate">{{
                  'SUBSCRIPTIONS.PRICE_TITLE_YEARLY' | translate }}</mat-option>
                <mat-option [value]="planPeriod.monthly">{{ 'SUBSCRIPTIONS.PRICE_TITLE_MONTHLY' | translate
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <small><a href="https://dinolingo.com/" target="_blank">{{
              'REGISTRATION.READ_MORE' | translate }}</a></small>
          <div class="inputGroup shifted">
            <mat-form-field class="select-dinostyle">
              <mat-select formControlName="paymentMethod" required
                placeholder="{{ 'REGISTRATION.CHOOSE_PAYMENT_METHOD' | translate }}" disableOptionCentering>
                <mat-option value="stripe">{{ 'PAYMENTS.CREDIT_CARD' | translate }} <img
                    src="assets/images/icons/credit-card.png" alt="Stripe" /></mat-option>
                <mat-option value="paypal">PayPal <img src="assets/images/logos/paypal-logo.png"
                    alt="PayPal" /></mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <small>{{ 'PROFILE.SEVEN_DAYS_FREE' | translate }}</small>
          <div class="terms newDinoStyle">
            <mat-checkbox color="primary" (change)="manageTerms()" [class]="{redAlert}">{{ 'PROFILE.TEXT_ACCEPT' |
              translate }}<br>
              <a href="https://www.dinolingo.com/terms-and-conditions/" target="_blank">{{ 'PROFILE.TEXT_TERMS' |
                translate
                }}</a>
              {{ 'PROFILE.TEXT_AND' | translate }}
              <a href="https://www.dinolingo.com/privacy-policy/" target="_blank">{{ 'PROFILE.TEXT_POLICY' |
                translate }}</a>.</mat-checkbox>
          </div>
          <div class="inputGroup nolabel">
            <button mat-raised-button class="login-btn s18" type="submit" [disabled]="formSent" color="accent"
              (click)="checkTerms()">
              {{ 'BUTTONS.CONTINUE' | translate }}
              <ng-container *ngIf="processWithGoogle"> with Google</ng-container>
              <ng-container *ngIf="processWithApple"> with Apple</ng-container>
            </button>
          </div>
        </form>

        <div class="app-store">
          <p class="app-store-title">{{ 'REGISTRATION.APP_STORE_TITLE' | translate }}</p>
          <div class="app-store-links">
            <a href="https://apps.apple.com/us/app/dinolingo-languages-for-kids/id1537076111" target="_blank">
              <img src="/assets/images/store/ios-white.png" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.dinolingo.dinolearning" target="_blank">
              <img src="/assets/images/store/gp-white.png" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="blue-zone"></div>
  <section class="reviews" #reviews id="reviews">
    <div class="content">
      <div class="arrow left" (click)="nextReview()">
        <img src="assets/images/icons/arr_white_left.svg" alt="arrow left" />
      </div>
      <div class="review" *ngFor="let review of reviewsList; let i = index" [ngClass]="{active: review.active}">
        <div>
          <div class="arrow left" (click)="nextReview()">
            <img src="assets/images/icons/arr_white_left.svg" alt="arrow left" />
          </div>
          <div>
            <div class="avatar">
              <img [src]="review.avatar" alt="avatar" />
            </div>
            <div class="name s21 m-s24">{{review.name}}</div>
            <div class="stars">
              <img src="assets/images/icons/star1.svg" alt="star" />
              <img src="assets/images/icons/star1.svg" alt="star" />
              <img src="assets/images/icons/star1.svg" alt="star" />
              <img src="assets/images/icons/star1.svg" alt="star" />
              <img src="assets/images/icons/star1.svg" alt="star" />
            </div>
          </div>
          <div class="arrow right" (click)="nextReview(false)">
            <img src="assets/images/icons/arr_white_right.svg" alt="arrow right" />
          </div>
        </div>
        <div class="text">
          <div class="lh51 s36 m-s28 m-lh36">{{review.review}}</div>
        </div>
      </div>
      <div class="arrow right" (click)="nextReview(false)">
        <img src="assets/images/icons/arr_white_right.svg" alt="arrow right" />
      </div>
    </div>
  </section>
  <section class="benefits" #benefits id="benefits">
    <h2 class="baloo s42 m-s32 m-lh36">{{ 'REGISTRATION.BENIFITS.TITLE' | translate }}</h2>
    <div class="content">
      <div class="benefit">
        <div><img src="assets/images/registration/benifit1.svg" alt="benifit 1" /></div>
        <h3 class="s21 baloo">{{ 'REGISTRATION.BENIFITS.BLOCK_1_TITLE' | translate }}</h3>
        <p class="s16 lh28">{{ 'REGISTRATION.BENIFITS.BLOCK_1_TEXT' | translate }}</p>
      </div>
      <div class="benefit">
        <div><img src="assets/images/registration/benifit2.svg" alt="benifit 2" /></div>
        <h3 class="s21 baloo">{{ 'REGISTRATION.BENIFITS.BLOCK_2_TITLE' | translate }}</h3>
        <p class="s16 lh28">{{ 'REGISTRATION.BENIFITS.BLOCK_2_TEXT' | translate }}</p>
      </div>
      <div class="benefit">
        <div><img src="assets/images/registration/benifit3.svg" alt="benifit 3" /></div>
        <h3 class="s21 baloo">{{ 'REGISTRATION.BENIFITS.BLOCK_3_TITLE' | translate }}</h3>
        <p class="s16 lh28">{{ 'REGISTRATION.BENIFITS.BLOCK_3_TEXT' | translate }}</p>
      </div>
    </div>
  </section>
  <div class="decorative-zone">
    <div>
      <div>
        <img src="assets/images/registration/ill10.svg" alt="decorative dinosaur" />
      </div>
    </div>
  </div>
  <section class="subscriptions" #subscriptions id="subscriptions">
    <div class="content">
      <h2 class="baloo s42 lh51 m-s32 m-lh36">{{ 'REGISTRATION.SUBSCRIPTIONS.TITLE' | translate }}</h2>
      <p class="s18 lh32 m-s16 m-lh26">{{ 'REGISTRATION.SUBSCRIPTIONS.TEXT' | translate }}</p>
      <div class="plans">
        <div class="plan">
          <h4 class="baloo s18">{{ 'REGISTRATION.SUBSCRIPTIONS.MONTHLY_SUBTITLE' | translate }}</h4>
          <div class="price s17"><small class="s24 baloo">$</small><span class="s60 baloo m-lh36">19</span>/ {{
            'SUBSCRIPTIONS.MONTH' | translate }}</div>
          <p class="s16 lh28">{{ 'REGISTRATION.SUBSCRIPTIONS.ITEM_1' | translate }}</p>
          <p class="s16 lh28">{{ 'REGISTRATION.SUBSCRIPTIONS.ITEM_2' | translate }}</p>
          <p class="s16 lh28">{{ 'REGISTRATION.SUBSCRIPTIONS.ITEM_3' | translate }}</p>
          <p class="s16 lh28">{{ 'REGISTRATION.SUBSCRIPTIONS.ITEM_4' | translate }}</p>
          <button mat-raised-button type="button" color="accent" class="s18"
            (click)="presetPlan(planPeriod.monthly); scroll(signup)">
            {{ 'BUTTONS.CONTINUE_FREE' | translate }}
          </button>
        </div>
        <div class="plan">
          <h4 class="baloo s18">{{ 'REGISTRATION.SUBSCRIPTIONS.YEARLY_SUBTITLE' | translate }}</h4>
          <div class="price s17"><small class="s24 baloo">$</small><span class="s60 baloo m-lh36">199</span>/ {{
            'SUBSCRIPTIONS.TEXT_YEAR' | translate }}</div>
          <p class="s16 lh28">{{ 'REGISTRATION.SUBSCRIPTIONS.ITEM_1' | translate }}</p>
          <p class="s16 lh28">{{ 'REGISTRATION.SUBSCRIPTIONS.ITEM_2' | translate }}</p>
          <p class="s16 lh28">{{ 'REGISTRATION.SUBSCRIPTIONS.ITEM_3' | translate }}</p>
          <p class="s16 lh28">{{ 'REGISTRATION.SUBSCRIPTIONS.ITEM_4' | translate }}</p>
          <button mat-raised-button type="button" color="accent" class="s18"
            (click)="presetPlan(planPeriod.yearly); scroll(signup)">
            {{ 'BUTTONS.CONTINUE_FREE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="content">
      <div class="logo">
        <a routerLink="{{ getURLLangPart() }}/">
          <img src="assets/images/logos/logo-wy.svg" class="logo" alt="DinoLingo Logo" />
        </a>
      </div>
      <nav>
        <div class="about">
          <p class="s15">{{ 'REGISTRATION.ABOUT' | translate }}</p>
          <a class="s17" href="https://www.dinolingo.com/how-it-works/" target="_blank">{{
            'REGISTRATION.LINK.HOW_IT_WORKS' | translate }}</a>
          <a class="s17" href="https://www.dinolingo.com/curriculum/" target="_blank">{{ 'REGISTRATION.LINK.CURRICULUM'
            | translate }}</a>
          <a class="s17" href="https://www.dinolingo.com/schools-teachers/" target="_blank">{{
            'REGISTRATION.LINK.SCHOOLS' | translate }}</a>
          <a class="s17" href="https://www.dinolingo.com/dinolingo-about/" target="_blank">{{ 'REGISTRATION.LINK.ABOUT'
            | translate }}</a>
          <a class="s17" href="https://www.dinolingo.com/dinolingo-cares/" target="_blank">{{ 'REGISTRATION.LINK.CARES'
            | translate }}</a>
        </div>
        <div class="product">
          <p class="s15">{{ 'REGISTRATION.PRODUCT' | translate }}</p>
          <a class="s17" href="https://www.dinolingo.com/dinolingo-app/" target="_blank">{{ 'REGISTRATION.LINK.APPS' |
            translate }}</a>
          <a class="s17" href="https://www.dinolingo.com/pricing/" target="_blank">{{ 'REGISTRATION.LINK.PRICING' |
            translate }}</a>
          <a class="s17" href="https://www.dinolingo.com/reviews/" target="_blank">{{ 'REGISTRATION.LINK.REVIEWS' |
            translate }}</a>
          <a class="s17" href="https://www.dinolingo.com/manage-subscription-billing/" target="_blank">{{
            'REGISTRATION.LINK.BILLING' | translate }}</a>
          <a class="s17" href="https://www.dinolingo.com/help/" target="_blank">{{ 'REGISTRATION.LINK.FAQ' | translate
            }}</a>
        </div>
        <div class="legal">
          <p class="s15">{{ 'REGISTRATION.LEGAL' | translate }}</p>
          <div>
            <a class="s17" href="https://www.dinolingo.com/privacy-policy/" target="_blank">{{
              'REGISTRATION.LINK.PRIVACY' | translate }}</a>
            <a class="s17" href="https://www.dinolingo.com/terms-and-conditions/" target="_blank">{{
              'REGISTRATION.LINK.TERMS' | translate }}</a>
          </div>
        </div>
      </nav>
      <div class="apps">
        <p class="s15">{{ 'REGISTRATION.DOWNLOAD_APP' | translate }}</p>
        <a href="https://apps.apple.com/app/dinolingo-kids-learn-languages/id1537076111" target="_blank">
          <img src="assets/images/store/appstore.png" alt="App Store" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.dinolingo.dinolearning" target="_blank">
          <img src="assets/images/store/playstore.png" alt="Google Play" />
        </a>
      </div>
    </div>
  </footer>
</article>